::-webkit-scrollbar {
  width: 8px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-button {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
  background: none !important;
}

.custom-side-scrollbar::-webkit-scrollbar {
  width: 6px;
  scroll-behavior: smooth;
}

.custom-side-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.custom-side-scrollbar::-webkit-scrollbar-thumb {
  background: #3c3c3c;
  border-radius: 10px;
}

.custom-side-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #777;
}

.custom-side-scrollbar::-webkit-scrollbar-button {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
  background: none !important;
}




input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: #fff !important;
  transition: background-color 5000s ease-in-out 0s;
}

input[type="checkbox"]:checked + label span {
  background-color: #2b2930;
  /* Adjust checked background color */
}

input[type="checkbox"] {
  background-color: #2b2930;
}

input[type="file"] {
  opacity: 0;
  cursor: pointer;
  font-size: 0;
  position: absolute;
}

.text-Link {
  color: #1a73e8;
  display: block;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.document-viewer {
  width: 100%;
  height: 100%;
}

.document-container {
  overflow: auto;
}

.my-masonry-grid {
  display: flex;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 15px;
  background-clip: padding-box;
}

.custom-underline-offset {
  text-underline-offset: 2px;
}

.emoji-picker-react .epr-emoji-name-container {
  display: none; /* Hides the "What's Your Mood?" section */
}


.custom-radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio + span {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  padding-bottom: 3px;
}

.custom-radio + span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #c4c4c4;
  border-radius: 50%;
  background: transparent;
}

.custom-radio:checked + span:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e4e4e4;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.markdown-body h2 {
  font-size: 1.5em;
  font-weight: bold;
}

.markdown-body ul {
  list-style-type: disc;
  padding-left: 1.5em;
}

.markdown-body li {
  margin-bottom: 0.5em;
}
.profile-text {
  text-align: center;
}
.profile-text2 {
  text-align: center;
}

.no-focus-outline {
  outline: none !important;
  box-shadow: none !important;
}

.crop-container {
  width: 100%;
  min-height: 400px;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #2c2c2c !important;
  color: #000;
  border: 1px solid #36343b !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  line-height: initial;
}

.react-datepicker__day {
  color: #c4c4c4 !important;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__day:hover {
  color: #202020 !important;
 
}
.react-datepicker__day--today {
  font-weight: bold;
  color: white !important;
}

.react-datepicker-popper {
  z-index: 100 !important; 
}

.react-datepicker__header {
  text-align: center;
  background-color: #e4e4e4 !important;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__navigation-icon::before {
  border-color: black !important;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker__day--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #cac4d0 !important;
}
.react-datepicker__day--selected {
  border-radius: 0.3rem;
  background-color: #1e69ea !important;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: #2c2c2c !important;
  border-bottom-right-radius: 0.3rem;
  color: #cac4d0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  background: #2c2c2c !important;
}

li.react-datepicker__time-list-item:hover{
  color:#202020 !important
}

.custom-calendar-btn {
  padding: 4px !important;
  margin: 0 !important;
  font-size: normal !important;
}


.get-started {
  margin-left: 0px;
}

.file-preview {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 10px;
  width: 250px;
  text-align: center;
}

.file-preview img,
.file-preview .thumbnail {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
  margin-bottom: 10px;
}

.file-preview p {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

.swiper-pagination {
  bottom: -5px !important;
}

.swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.4);
  width: 10px;
  height: 10px;
  margin: 0 5px;
}
.swiper-pagination-bullet-active {
  background-color: #d0bcff !important; /* Ensure the active bullet color is applied */
  width: 20px !important;
  height: 8px !important;
  opacity: 1 !important;
  border-radius: 50% / 100% !important;
}

@keyframes flicker {
  0% {
    background-color: #202020; /* Light color */
  }
  50% {
    background-color: #333333; /* Slightly darker */
  }
  100% {
    background-color: #202020; /* Back to light */
  }
}

.flicker-highlight {
  animation: flicker 1s linear infinite;
}


@media (max-width: 640px) {
  .get-started {
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .profile-text {
    text-align: start;
  }
  .swiper-pagination-bullet {
    display: none !important;
  }
}
@media (min-width: 1024px) {
  .profile-text2 {
    text-align: start;
  }
}

@media (min-width: 470px) {
  .xs\:hidden {
    visibility: hidden;
    display: none;
  }
}
